<template>
  <v-card tile>

    <div v-if="shipping.bill_of_ladings.length > 0">
      <v-card-title>{{ $t('orders.bl_number') }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-simple-table dense class="cargos-table mt-3">
          <template v-slot:default>
            <thead>
              <tr>
                <th>{{ $tc('orders.bl_number') }}</th>
                <th>{{ $tc('quantity') }}</th>
                <th>{{ $tc('cbm') }}</th>
                <th>{{ $tc('components.products.weight') }}</th>
                <th>{{ $tc('copy') }}</th>
                <th>{{ $tc('original') }}</th>
                <th>{{ $tc('telex') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(c) in shipping.bill_of_ladings" :key="c.id">
                <td>{{ c.bl_number }}</td>
                <td>{{ c.quantity }}</td>
                <td>{{ c.cbm }}</td>
                <td>{{ c.weight }}</td>
                <td>
                  <v-btn 
                    v-if="c.copy" 
                    :href="c.copy.download_url"
                    x-small  
                  >{{ $t('view_file') }}</v-btn>
                </td>
                <td>
                  <v-btn 
                    v-if="c.original" 
                    :href="c.original.download_url"
                    x-small  
                  >{{ $t('view_file') }}</v-btn>
                </td>
                <td>
                  <v-btn 
                    v-if="c.telex" 
                    :href="c.telex.download_url"
                    x-small  
                  >{{ $t('view_file') }}</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </div>
    
    <v-card-title>{{ $t('orders.cargos') }}</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-simple-table dense class="cargos-table mt-3" v-if="shipping.cargos.length > 0">
        <template v-slot:default>
          <tbody>
            <tr v-for="(c, c_index) in shipping.cargos" :key="c.id">
              <td class="pa-2">
                <v-chip-group column>
                  <v-tooltip
                    right
                    color="rgba(255, 255, 255, 1)"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        dark
                        small
                        color="blue-grey lighten-1"
                        @click="openCargoModal(c)"
                      >{{ c.container_number === null ? c.id : c.container_number }} {{ c.capacity }}</v-chip>
                    </template>
                    <cargo-content-card 
                      :cargo="c"
                      :hide-footer="true"
                    ></cargo-content-card>
                  </v-tooltip>
                </v-chip-group>
              </td>
              <td class="pa-2">
                <tr v-for="o in c.orders" :key="o.id">
                  <td>
                    <input
                      type="checkbox"
                      v-model="o.checked" 
                    > {{ `${o.sku} - ${o.company.name}` }}
                  </td>
                </tr>
              </td>
              <td class="text-right pa-2">
                <v-btn
                  color="indigo"
                  dark
                  elevation="1"
                  small
                  @click="generateDoc('pl', c_index)"
                >{{ $t('orders.gen_cargo_pl') }}</v-btn>
                <v-btn
                  class="ml-2"
                  color="primary"
                  dark
                  elevation="1"
                  small
                  @click="generateDoc('inv', c_index)"
                >{{ $t('orders.gen_cargo_invoice') }}</v-btn>
                <v-btn
                  v-if="shipping.orders[0].incoterm === 'FOB'"
                  class="ml-2"
                  color="blue"
                  dark
                  elevation="1"
                  small
                  @click="generateDoc('inv_customer', c_index)"
                >{{ $t('orders.gen_cargo_invoice_client') }}</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-else class="mt-3">{{ $t('orders.no_cargos_assigned') }}</div>
    </v-card-text>

    <v-card-title>{{ $t('documents') }}</v-card-title>
    <v-divider></v-divider>
    <v-card-text v-if="shipping.documents.length > 0">
      <div
        v-for="doc in shipping.documents"
        :key="doc.id"
        class="d-flex justify-space-between pa-2"
        style="border-bottom: 1px solid #E2E2E2;"
      >
        <div><strong>{{ doc.key_id }}</strong><br>{{ doc.description }}</div>
        <div>
          <v-btn
            x-small
            target="_blank"
            :href="doc.url"
            color="primary"
            elevation="1"
          >{{ $t('download') }}</v-btn>
        </div>
      </div>
    </v-card-text>
    <v-card-text v-else><div>{{ $t('no_documents') }}</div></v-card-text>
    <v-dialog
      v-model="cargo_content_modal"
    >
      <cargo-content-card 
        v-if="cargo_content_modal_data !== null"
        :cargo="cargo_content_modal_data"
      ></cargo-content-card>
    </v-dialog>
  </v-card>
</template>

<script>
import cargoContentCard from '../orders/cargoContentCard.vue';
export default {
  name: 'shippingDocuments',
  props: ['shipping'],
  components: {
    cargoContentCard
  },
  data() {
    return {
      documentsHeader: [],
      modalItems: [],
      cargo_content_modal: false,
      cargo_content_modal_data: null,
    }
  },
  watch: {
    shipping: {
      deep: true,
      handler() {
        console.log(1);
      }
    }
  },
  methods: {
    generateDoc(type, c_index) {
      var oids = this.shipping.cargos[c_index].orders.filter(o => o.checked === true).map(o => o.id ).join(',');
      var url;
      if(type === 'pl') {
        url = this.shipping.cargos[c_index].packing_list_url+'?order_ids='+oids;
      } else if(type === 'inv_customer') { 
        url = `https://sps.inmarcopolo.com/api/broker/cargo/${this.shipping.cargos[c_index].id}/custom_invoice?company_id=${this.shipping.orders[0].company.id}&incoterm=FOB&order_ids=${oids}`;
      } else {
        url = this.shipping.cargos[c_index].invoice_url+'?order_ids='+oids;
      }
      window.open(url);
    },
    openCargoModal(cargo) {
      this.cargo_content_modal = !this.cargo_content_modal;
      this.cargo_content_modal_data = cargo;
    }
  },
  created() {
    this.shipping.bill_of_ladings.map( bl => {
      bl.copy = bl.files.find(d => d.type === 'COPY');
      bl.original = bl.files.find(d => d.type === 'ORIGINAL');
      bl.telex = bl.files.find(d => d.type === 'TELEX_RELEASE');
    });
    console.log(this.shipping);
  }
}
</script>

<style>
.cargos-table {
  padding: 5px;
  border: 1px solid #CCC;
}
</style>
