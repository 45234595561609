<template>
  <v-container fluid>
    <PageHeader :title="$t('shipping')" :subheader="''" />
    <v-card class="pa-5">
      <v-data-table
        :loading="loading"
        :headers="table_headers"
        :items="shipping_orders"
        :search="search"
        :items-per-page="pageSize"
        :page.sync="page"
        :server-items-length="pageCount"
        hide-default-footer
      >
        <template v-slot:top>
          <v-text-field
            append-icon="mdi-magnify"
            v-model="search"
            :label="$t('search')"
            @keyup.enter="loadShippingOrders"
            @click:clear="loadShippingOrders(true)"
            clearable
          ></v-text-field>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <v-icon v-if="item.type === 'port'">mdi-ferry</v-icon>
          <v-icon v-if="item.type === 'airport'">mdi-airplane</v-icon>
          <span style="font-size: 9px;">({{ item.id }})</span>
        </template>
        <template v-slot:[`item.cargos`]="{ item, index }">
          <v-chip-group
            column
          >
            <v-tooltip
              right
              color="rgba(255, 255, 255, 1)"
              v-for="cargo in item.cargos"
              :key="cargo.id"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  dark
                  small
                  color="blue-grey lighten-1"
                  @click="openCargoModal(cargo)"
                  @mouseenter="getShippingOrder(index)"
                >{{ cargo.capacity }}</v-chip>
              </template>
              <cargo-content-card 
                :cargo="cargo"
                :hide-footer="true"
              ></cargo-content-card>
            </v-tooltip>
          </v-chip-group>
        </template>
        <template v-slot:[`item.from_to`]="{ item }">
          <div class="text-caption"><strong>{{ $t('origin') }}: </strong>{{ item.origin_name }}</div>
          <div class="text-caption"><strong>{{ $t('destination') }}: </strong>{{ item.destination_name }}</div>
        </template>
        <template v-slot:[`item.departure_date`]="{ item }">
          {{ formatDate(item.tracking_etd !== null ? item.tracking_etd : item.departure_date) }}
        </template>
        <template v-slot:[`item.arrival_date`]="{ item }">
          {{ formatDate(item.tracking_eta !== null ? item.tracking_eta : item.arrival_date) }}
        </template>
        <template v-slot:[`item.order_status`]="{ item }">
          <v-chip
            v-if="item.order_status === 'on the way'"
            dark
            color="green"
            small
          >{{ $t('orders.on_the_way') }}</v-chip>
          <v-chip
            v-if="item.order_status === 'arrived'"
            dark
            color="secondary"
            small
          >{{ $t('orders.arrived') }}</v-chip>
          <v-chip
            v-if="item.order_status === 'waiting for departure'"
            dark
            color="indigo"
            small
          >{{ $t('orders.waiting_for_departure') }}</v-chip>
          <div class="text-caption"><strong>{{ $t('payments') }}: </strong> {{ item.paid_vs_total }}</div>
        </template>
        <template v-slot:[`item.actions`]="{ item, index }">
          <v-btn
            v-if="false"
            fab
            color="indigo"
            dark
            small
            @click="openDialog(item, 'contents')"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-btn
            fab
            color="primary"
            dark
            small
            @click="getShippingOrder(index, 'files')"
          >
            <v-icon>mdi-file-search-outline</v-icon>
          </v-btn>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                :color="item.dispatched_at !== null ? 'green' : 'secondary'"
                dark
                small
                @click="item.dispatched_at !== null ? false : (dispatchDialog = true, dispatching = item.id)"
              >
                <v-icon>mdi-check-decagram</v-icon>
              </v-btn>
            </template>
            <div v-if="item.dispatched_at !== null">{{ $t('orders.dispatched') }}</div>
            <div v-else>{{ $t('orders.mark_as_dispatched') }}</div>
          </v-tooltip>
        </template>
        <template v-slot:footer>
          <v-pagination
            class="mt-3"
            v-model="page"
            :length="pageCount"
          ></v-pagination>
        </template>
      </v-data-table>
      <loader v-model="loading"></loader>
    </v-card>
    <v-dialog
      fullscreen
      v-model="dialog"
    >
      <v-toolbar
        flat
        dark
        color="primary"
      >
        <v-toolbar-title>{{ $tc('shipping_orders', 1) }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <shipping-documents :shipping="shippingDocData"></shipping-documents>
    </v-dialog>
    <v-dialog
      v-model="dispatchDialog"
      max-width="720"
    >
      <v-card>
        <v-card-title>{{ $t('orders.mark_as_dispatched') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <text-field-datepicker v-model="dispatchedDate" :label="$t('date')"></text-field-datepicker>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            dark
            @click="dispatchIt"
            :loading="loading"
          >{{ $t('orders.mark_as_dispatched') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="cargo_content_modal"
    >
      <cargo-content-card 
        v-if="cargo_content_modal_data !== null"
        :cargo="cargo_content_modal_data"
      ></cargo-content-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue';
import PageHeader from '../components/PageHeader';
import textFieldDatepicker from '../components/textFieldDatepicker.vue';
import cargoContentCard from '../components/orders/cargoContentCard.vue';
import shippingDocuments from '../components/shipments/shippingDocuments.vue';
import loader from '../components/loadingBox.vue';
import { SHIPPING_ORDERS_MARK_AS_DISPATCHED, SHIPPING_ORDERS_GET, SHIPPING_ORDERS_GET_DETAILS } from '../constants/endpoints';

export default {
  name: 'Orders',
  components: {
    PageHeader,
    textFieldDatepicker,
    cargoContentCard,
    shippingDocuments,
    loader
  },
  data() {
    return {
      loading: true,
      table_headers: [
        { text: 'Ref', align: 'center', sortable: true, value: 'id' },
        { text: this.$t('orders.cargos'), align: 'start', sortable: false, value: 'cargos' },
        { text: this.$tc('order', 2), align: 'start', sortable: false, searchable: true, value: 'order_numbers' },
        { text: this.$t('orders.bl_number'), align: 'start', sortable: true, value: 'bl_number' },
        { text: this.$t('orders.forwarder'), align: 'start', sortable: true, value: 'forwarder_office_name' },
        { text: this.$t('orders.from_to'), align: 'start', sortable: true, value: 'from_to' },
        { text: this.$t('orders.departure_date'), align: 'start', sortable: true, value: 'departure_date' },
        { text: this.$t('orders.arrival_date'), align: 'start', sortable: true, value: 'arrival_date' },
        { text: this.$t('status'), align: 'start', sortable: true, value: 'order_status' },
        { text: '', align: 'end', sortable: false, value: 'actions' }
      ],
      documentsHeader: [
        { text: this.$t('ref'), align: 'start', sortable: true, value: 'key_id' },
        { text: this.$t('orders.notes'), align: 'start', sortable: true, value: 'description' },
        { text: '', align: 'end', sortable: true, value: 'file' },
      ],
      modalItems: '',
      shipping_orders: [],
      dialog: false,
      dialogContents: false,
      search: '',
      dispatchedDate: null,
      dispatching: '',
      dispatchDialog: false,
      cargo_content_modal: false,
      cargo_content_modal_data: null,
      shippingDocData: null,
      page: 1,
      pageCount: 1,
      pageSize: 15
    }
  },
  watch: {
    page() {
      this.loadShippingOrders();
    }
  },
  methods: {
    getShippingOrder(so_index, type) {
      if(this.shipping_orders[so_index].documents === undefined) {
        this.$http.get(this.buildEndpoint(SHIPPING_ORDERS_GET_DETAILS, { shipping_order_id: this.shipping_orders[so_index].id}))
        .then(resp => {
          //this.shipping_orders[so_index] = resp.data.data;
          Vue.set(this.shipping_orders, so_index, resp.data.data);
          this.buildSoData(this.shipping_orders);
          this.openDialog(this.shipping_orders[so_index], type);
        });
      } else {
        this.openDialog(this.shipping_orders[so_index], type);
      }
    },
    buildSoData(so) {
      for(var i in so) {
        var orders = '';
        if(so[i].orders.length == 0) {
          orders += so[i].description !== null ? so[i].description : '';
        }
        for(var io in so[i].orders) {
          orders += so[i].orders[io].sku + ` (${so[i].orders[io].company.name}), `;
          so[i].payments.push(...so[i].orders[io].payments);
        }
        var paid = so[i].payments.filter(p => p.status === "2");
        so[i].paid_vs_total = `${paid.length}/${so[i].payments.length}`;
        so[i].order_numbers = orders.replace(/,\s*$/, "");
      }
    },
    loadShippingOrders(clear = false) {
      if(clear === true) {//has to be === true because clear get an event
        this.search = '';
      }
      this.loading = true;
      this.$http.get(this.buildEndpoint(SHIPPING_ORDERS_GET), {
        params: {
          pageSize: 15,
          pageNumber: this.page,
          search: this.search
        }
      }).then(resp => {
        //console.log(resp.data.data);
        this.page = resp.data.data.pagination.currentPage;
        this.pageCount = resp.data.data.pagination.totalPages;
        var so = resp.data.data.records;
        //console.log(so);
        this.buildSoData(so);
        this.shipping_orders = so;
        this.loading = false;
      });
    },
    openDialog(item, type) {
      this.modalItems = []
      if(item.cargos.length > 0) {
        item.cargos.map( c => {
          this.modalItems.push(
            {
              key_id: 'cargo_invoice',
              description: this.$t('orders.cargo_invoice'),
              url: c.invoice_url
            },
            {
              key_id: 'packing_list_invoice',
              description: this.$t('orders.packing_list_invoice'),
              url: c.packing_list_url
            },
          )
        })
      }
      if(item.documents.length > 0) {
        item.documents.map( d => {
          this.modalItems.push(d);
        })
      }
      if(type === 'files') {
        this.shippingDocData = item;
        this.dialog = true;
      }
      if(type === 'contents') {
        this.dialogContents = true;
      }
    },
    dispatchIt() {
      this.loading = true
      this.$http.post(this.buildEndpoint(SHIPPING_ORDERS_MARK_AS_DISPATCHED, { id: this.dispatching }),
        {
          dispatched_at: this.dispatchedDate
        }
      ).then(() => {
        this.loadShippingOrders()
        this.dispatchDialog = false
        this.dispatching = ''
        this.dispatchedDate = null
      })
    },
    openCargoModal(cargo) {
      this.cargo_content_modal = !this.cargo_content_modal;
      this.cargo_content_modal_data = cargo;
    }
  },
  mounted() {
    this.loadShippingOrders()
  }
}

</script>

